// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons";

@import "~toastr/toastr";
// @import "flatpickr";

/*.card{border:0px !important;background:none !important}*/
h2 {
    text-transform: uppercase;
}

.bi {
    font-size: 20px !important;
}

a {
    text-decoration: none !important;
}

main > .container {
    /*padding: 90px 15px 90px;*/
    margin-top: 90px;
}

footer {
    position: fixed;
    height: 60px;
    bottom: 0;
    width: 100%;
}
